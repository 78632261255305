import { useAuthStore } from '~/stores/auth';
import { useLayoutStore } from '~/stores/layout';

export default defineNuxtRouteMiddleware((to) => {
  if (process.server) {
    return;
  }

  const { getUserData } = useAuthStore();
  const subPages = [
    'in',
    'out',
  ];

  const { setPageLoader } = useLayoutStore();

  setPageLoader(false);

  const pageType = to.params?.pageType;
  const isSubPage = pageType && subPages.includes(pageType[0]) && pageType.length === 1;

  if (!pageType || isSubPage) {
    if (!getUserData.profileFilled) {
      useSessionStorage.set('cashierRedirect', true);

      return navigateTo('/cabinet/personal-data', { external: true });
    }

    if (!isSubPage) {
      return navigateTo('/cashier/in', { external: true });
    };
  } else {
    throw createError({
      statusCode: 404,
      message: 'Page not found',
      fatal: true,
    });
  }
});
